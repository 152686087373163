'use client'

import { useTranslations } from 'next-intl'
import Link from 'next/link'
import {
    NavigationMenu,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    navigationMenuTriggerStyle,
} from '@repo/ui/navigation-menu'
import type { me } from 'actions/auth'
import { use, useMemo } from 'react'
import { Role } from '@repo/types/user/User'
import type Promiseable from '@repo/types/promise/Promiseable'

export default function MainNav({ user }: { user?: Promiseable<Awaited<ReturnType<typeof me>> | null> }) {
    user ??= null
    if (user instanceof Promise) user = use(user)

    const t = useTranslations('header')

    const isStudent = useMemo(() => {
        if (!user) return false
        return Role.compare(user, Role.Student) === Role.Relationship.Same
    }, [user])

    return (
        <div className='mr-4 hidden md:flex'>
            <Link href='/' className='mr-4 flex items-center space-x-2 lg:mr-6'>
                <h1 title={t('placeholder')} className='hidden font-bold lg:inline-block select-none'>
                    {t('title')}
                </h1>
            </Link>

            {
                !isStudent && (
                    <NavigationMenu>
                        <NavigationMenuList>
                            {
                                user && (
                                    <NavigationMenuItem>
                                        <Link href='/dashboard' legacyBehavior passHref>
                                            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                                                {t('nav.dashboard')}
                                            </NavigationMenuLink>
                                        </Link>
                                    </NavigationMenuItem>
                                )
                            }

                            <NavigationMenuItem>
                                <Link href='/explore' legacyBehavior passHref>
                                    <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                                        {t('nav.explore')}
                                    </NavigationMenuLink>
                                </Link>
                            </NavigationMenuItem>

                            <NavigationMenuItem>
                                <Link href='/docs' legacyBehavior passHref>
                                    <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                                        {t('nav.docs')}
                                    </NavigationMenuLink>
                                </Link>
                            </NavigationMenuItem>
                        </NavigationMenuList>
                    </NavigationMenu>
                )
            }
        </div>
    )
}
