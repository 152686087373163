'use client'

import type Promiseable from '@repo/types/promise/Promiseable'
import { ID } from '@repo/types/user/User'
import { Avatar, AvatarImage, AvatarFallback } from '@repo/ui/avatar'
import { Button } from '@repo/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@repo/ui/dropdown-menu'
import { Skeleton } from '@repo/ui/skeleton'
import { UserRoundIcon } from 'lucide-react'
import Link from 'next/link'
import { Suspense, use, useMemo } from 'react'
import type { me } from 'actions/auth'
import { useTranslations } from 'next-intl'

export default function UserMenu({
    user,
}: { user?: Promiseable<Exclude<Awaited<ReturnType<typeof me>>, undefined> | null> }) {
    user ??= null
    if (user instanceof Promise) user = use(user)

    const name = useMemo(() => ID.is(user) || typeof user !== 'object' ? undefined : user?.name, [user])
    const id = useMemo(() => ID.get(user), [user])

    const t = useTranslations('header')

    return (
        <Suspense
            fallback={
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant={null} size='icon'>
                            <Avatar>
                                <AvatarFallback>
                                    <UserRoundIcon />
                                </AvatarFallback>
                            </Avatar>

                            <span className='sr-only'>Open user toggle</span>
                        </Button>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent align='end'>
                        <DropdownMenuLabel>
                            <Skeleton className='w-[10ch] h-4' />
                        </DropdownMenuLabel>

                        <DropdownMenuSeparator />

                        {
                            (['personal', 'personal_settings', 'logout'] as const).map(x => (
                                <Skeleton key={`skeleton_${x}`} className='w-[10ch] h-4' />
                            ))
                        }
                    </DropdownMenuContent>
                </DropdownMenu>
            }
        >
            {
                user && (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant={null} size='icon'>
                                <Avatar>
                                    <AvatarImage src='https://github.com/mikelpint.png' />
                                    <AvatarFallback>
                                        <UserRoundIcon />
                                    </AvatarFallback>
                                </Avatar>

                                <span className='sr-only'>Open user toggle</span>
                            </Button>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent align='end'>
                            <DropdownMenuLabel>
                                {
                                    name && (
                                        <>
                                            {`${name} `}
                                            <span className='text-xs text-muted-foreground'>{`(@${id})`}</span>
                                        </>
                                    )
                                }

                                {
                                    !name && `@${id}`
                                }
                            </DropdownMenuLabel>

                            <DropdownMenuSeparator />
                            <Link href='/personal'>
                                <DropdownMenuItem>{t('user.profile')}</DropdownMenuItem>
                            </Link>

                            <Link href='/personal/settings'>
                                <DropdownMenuItem>{t('user.settings')}</DropdownMenuItem>
                            </Link>

                            <Link href='/logout'>
                                <DropdownMenuItem>{t('user.logout')}</DropdownMenuItem>
                            </Link>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            }
        </Suspense>
    )
}
